@import "../variables.scss";

.header {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.contact-details a,img {
  vertical-align: middle;
}

.main-header {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  .logo {
    height: 7em;

    img {
      height: 100%;
      width: auto;
    }
  }
}

.utility-header {
  height: 45px;
}

.utility {
  height: 100%;
}

.nav-item-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 0.67em 1.5em;
  display: flex;

  &:hover {
    background: #ececec;
    color: #000;
  }
}

.menu {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  &:hover {
    color: $green;
  }
}

.menu-link {
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  color: inherit;
}

.menu-image {
  width: 40px;
  height: 32px;
  margin-right: 10px;
}

.menu-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.menu-item-text {
  font-weight: bold;
}

.menu-item-subtext {
  font-size: 0.9em;
  text-align: left;
}

.utility-header__menu {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

header .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav {
  display: flex;

  li {
    color: #000;
    display: block;
    padding: 2.16667rem 0.66667rem;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600;

    a {
      display: flex;
      img {
        margin-right: 6px;
      }
      &:hover {
        color: $green;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .nav li {
    font-size: 1em;
  }
}

@media screen and (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  header .utility {
    font-size: 0.85em;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }

  .menu-link {
    font-size: 0.8em;
  }

  .burger-menu {
    margin: 1rem 0 1rem 1rem;
    width: auto;
    display: flex;
    align-items: center;

    svg {
      height: 24px;
      width: 24px;
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  #utility-header-label {
    display: none;
  }
  .main-header .logo {
    height: 7.5em;
  }
  .main-header .container {
    padding: 0;
  }
  .menu-link {
    font-size: 0.7em;
    flex-direction: column;
    height: 48px;
    .menu-image {
      margin-right: 0;
    }
    .menu-item-text {
      margin-top: 8px;
    }
  }

  .product .actions-wrapper {
    margin-bottom: 0;
    .add-btn {
      font-size: 0.65em;
    }
  }

  .nav-item-container svg {
    margin-right: 0;
  }
}

/* Media query for 2 products per row */
@media screen and (max-width: 660px) {
  .menu-link {
    font-size: 0.6em;
    flex-direction: column;
    height: 48px;
    .menu-image {
      margin-right: 0;
    }
    .menu-item-text {
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 540px) {
  .app-title {
    font-size: 1.5em;
  }
  .menu-link {
    font-size: 0.6em;
    flex-direction: column;
    height: 48px;
    .menu-image {
      margin-right: 0;
    }
    .menu-item-text {
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 480px) {
  .app-title {
    margin: 24px 0;
  }
  .utility.container {
    padding-left: 0;
    padding-right: 0;
  }
  .main-header .logo {
    height: 7.5em;
  }
  .menu-item {
    padding: 10px;
    .menu-image {
      height: 24px;
    }
  }
  .standard-header__menu-text {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 400px) {
  .main-header .logo {
    height: 4em;
    width: auto;
  }

  .menu {
    margin: 0;
    .menu-item {
      padding: 7px;
    }
  }

  .contact-details {
    font-size: 0.7em;
  }
}

// thanks to pesky fold devices, devs have more work to do...
@media screen and (max-width: 320px) {
  .main-header .logo {
    height: 4em;
    width: auto;
  }

  .menu {
    margin: 0;
    .menu-item {
      padding: 7px;
      font-size: 0.7em;
      img {
        width: 20px;
      }
    }
  }

  .contact-details {
    font-size: 0.7em;
  }
}
